import React from "react"
import { MailOutlined } from '@ant-design/icons' // PhoneOutlined
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom"

const Footer = () => {
	return(
		<div id="footer">
            <svg width="3840" height="150" id="footer-wave">
                <path d="M3839 149.5H-1c74.36-1.605 150.725-3.837 229.094-6.696 221.516-8.08 321.984-67.618 548.616-72.244 74.158-1.514 269.455 14.036 314.054 16.338 107.71 5.56 220.833 5.654 339.37.281 251.428-11.395 581.013-46.117 865.18-70.939C2484.76-.307 2679.404-4.21 2879.25 4.534c88.526 7.665 144.768 13.21 168.724 16.632 205.7 29.385 415.795 85.758 544.775 102.294 48.153 6.174 130.237 14.854 246.251 26.04z" fill="#181c32"></path>
            </svg>
            <Container>

                <Row>
                    <Col sm={2}>
                        <h6>
                            <Link to={'/about'}>Highway to Tech</Link>
                        </h6>
                        <nav className={'footer-nav'}>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/case-studies">Case Studies</Link></li>
                                <li><Link to="/tech">Technologies</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </nav>
                    </Col>

                    <Col sm={4}>
                        <h6>
                            <Link to={'/contact'}>Ring Our Bell</Link>
                        </h6>
                        <nav className={'footer-nav'}>
                            <ul>
                                {/*<li><CompassOutlined /> 10 David Elazar St, Suite 1364, Haifa, Israel</li>*/}
                                <li><MailOutlined /> <a href="mailto:office@codenroll.co.il">office@codenroll.co.il</a></li>
                                {/* <li><PhoneOutlined /> <a href="tel:972534626311">+972 53 4626 311</a></li> */}
                                
                            </ul>
                        </nav>
                    </Col>
                </Row>
                <svg id="footer-logo" viewBox="0 0 363.48 112.17"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon className="cls-1" points="153.77 112.17 184.73 64.13 205.36 79.43 225.24 0 197.47 54.99 175.66 41.76 153.77 112.17"/><polygon className="cls-2" points="225.24 0 194.29 48.04 172.7 33.92 153.77 112.17 178.71 49.03 199.82 63.74 225.24 0"/><path className="cls-1" d="M28.53,33.31C18.36,33.31,7,38.45,4.35,48.92L.46,64.47C-2.15,74.88,6.71,80.08,16.88,80.08s21.56-5.2,24.17-15.61l.17-.7H28.85l-.18.7c-.82,3.28-5.18,5-9.12,5s-7.55-1.61-6.71-5l3.89-15.55c.84-3.34,5.16-5.14,9-5.14s7.29,1.48,6.86,4.66H45c2.21-10.08-6.51-15.13-16.51-15.13Z"/><path className="cls-1" d="M95.35,34,84,79.44h18.32c9.81.06,20.14-4.5,22.77-15l3.83-15.29C131.53,38.58,123.5,34,113.68,34Zm3.76,34.69,6-24h6.29c4.15,0,5.69,1.87,4.95,4.82l-3.56,14.2c-.74,3-3.17,4.94-7.39,4.94Z"/><path className="cls-1" d="M145.54,44.75h21.57l2.7-10.8H135.73l-11.4,45.49h34.09l2.72-10.86H139.57l1.59-6.34H161l2.77-11.08H143.93Z"/><path className="cls-1" d="M292.87,79.44h32.36l2.85-11.37H308.16L316.71,34H304.26Z"/><path className="cls-1" d="M44.13,64.47c-2.61,10.41,6.25,15.61,16.42,15.61s21.56-5.2,24.17-15.61l3.89-15.55c2.61-10.4-6.25-15.61-16.41-15.61S50.65,38.45,48,48.92ZM60.4,48.92c.84-3.34,5.16-5.14,9-5.14s7.69,1.61,6.81,5.14l-3.9,15.55c-.82,3.28-5.18,5-9.12,5s-7.55-1.61-6.71-5Z"/><path className="cls-1" d="M234.5,79.44h15.42l-9.44-15.61c6.39-1.74,11.24-6.49,13.35-14.91,2.4-10.66-4.34-15-14.44-15H219.83l-11.4,45.49H221l4.31-17.2Zm-7.45-24.35,2.6-10.41h7.26c7.82,0,5.21,10.41-2.6,10.41Z"/><path className="cls-1" d="M253,64.47c-2.61,10.41,6.25,15.61,16.42,15.61s21.56-5.2,24.17-15.61l3.89-15.55c2.61-10.4-6.25-15.61-16.41-15.61s-21.55,5.14-24.17,15.61ZM269.3,48.92c.84-3.34,5.16-5.14,9-5.14s7.69,1.61,6.81,5.14l-3.9,15.55c-.82,3.28-5.18,5-9.12,5s-7.55-1.61-6.71-5Z"/><path className="cls-1" d="M328.27,79.44h32.36l2.85-11.37H343.56L352.11,34H339.66Z"/></g></g></svg>
                <hr />
                <p id="copyr">© 2025 Codenroll ~ Software Development Company ~ All rights reserved</p>
            </Container>
        </div>	
	);
}

export default Footer;