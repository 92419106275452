// React
import React, { useState, useEffect } from "react";

// Utilities
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";

// User Interface
import { stack as Menu } from 'react-burger-menu';

import Wave		from './components/wave';
import Header   from './components/header';
import Footer   from './components/footer';

import Home from './views/home';
import About from './views/about';
import CaseStudies from './views/caseStudies';
import Contact from './views/contact';
import Services from './views/services';
import Technologies from './views/tech';
import CaseStudy from './views/case-study';
// import { ContextProvider } from './context';

// Style
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';

import { UpCircleOutlined } from '@ant-design/icons';

function App() {
	const [appclasses, setAppclasses] = useState('');
	const [menuOpen, setMenuOpen] = useState(false);
	
	const declareAppClasses = () => {
		let path = window.location.pathname;

		let output = '';

		if( path === '/' )
			output = 'page-home';
		else if( path === '/about' )
			output = 'page-about';
		else if( path === '/services' )
			output = 'page-services';
		else if( path === '/contact' )
			output = 'page-contact';
		else if( path === '/case-studies' )
			output = 'page-case-studies';
		else if( path === '/tech')
			output = 'page-tech';
		else if( path.length > 14 && path.substring(0, 14) === '/case-studies/' )
			output = 'page-case-study';
		else if( path.substring(0, 7) === '/quote/' )
			output = 'quote';
			
		setAppclasses( output );
	}
	
	const handleStateChange = (state) => {
		setMenuOpen(state.isOpen);
    }
	
	useEffect(() => {
		declareAppClasses();
	});
	
	return (
        // <ContextProvider>
		<Router>
			<div id="wrap" className={appclasses}>
				<div id="sitebg">
                    <Wave />
                </div>
                
                {window.innerWidth < 768 &&
                <Menu 
                    // noOverlay
                    isOpen={menuOpen}
                    onStateChange={(state) => handleStateChange(state)}
                    width={ 280 }
                    pageWrapId={ "page-wrap" } 
                    outerContainerId={ "wrap" }
                >
                    <Link to="/" onClick={() => setMenuOpen(false)}>{'Home'}</Link>
                    <Link to="/about" onClick={() => setMenuOpen(false)}>{'About'}</Link>
                    <Link to="/services" onClick={() => setMenuOpen(false)}>{'Services'}</Link>
                    <Link to="/tech" onClick={() => setMenuOpen(false)}>{'Technologies'}</Link>
                    <Link to="/case-studies" onClick={() => setMenuOpen(false)}>{'Case Studies'}</Link>
                    <Link to="/contact" onClick={() => setMenuOpen(false)}>{'Contact'}</Link>
                </Menu>
                }

				<div>
                    <Header />
                    
					<Routes>
						<Route exact path="/" element={<Home declareAppClasses={declareAppClasses} />}/>
						<Route exact path="/about" element={<About declareAppClasses={declareAppClasses} />}/>
						<Route exact path="/services" element={<Services declareAppClasses={declareAppClasses} />}/>
						<Route exact path="/tech" element={<Technologies declareAppClasses={declareAppClasses} />}/>
						<Route exact path="/contact" element={<Contact declareAppClasses={declareAppClasses} />}/>
						<Route exact path="/case-studies" element={<CaseStudies  declareAppClasses={declareAppClasses} />} />
						<Route exact path="/case-studies/:slug" element={<CaseStudy declareAppClasses={declareAppClasses} />}/>
					</Routes>
					
					<Footer />
                    <button type="button" id="scroll2top" onClick={window.scrollTo({top: 0, behavior: 'smooth'})}>
                        <span className="sr-only">Scroll to top</span>
                        <UpCircleOutlined />
                    </button>
				</div>
			</div>
		</Router>
        // </ContextProvider>
	);
}

export default App;
